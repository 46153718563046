/* Base styles */
.privacy-policy {
    font-family: Arial, sans-serif;
    padding: 20px;
}

h1 {
    font-size: 24px;
    margin-top: 0;
}

.img {
    max-width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}


h2 {
    font-size: 20px;
    margin-top: 20px;
}

h3 {
    font-size: 18px;
    margin-top: 15px;
}

p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 15px;
}

ul {
    list-style-type: disc;
    margin-left: 20px;
}

a {
    color: #007bff;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

/* Responsive styles */
@media (max-width: 767px) {
    .privacy-policy {
        padding: 10px;
    }

    h1 {
        font-size: 22px;
    }

    h2 {
        font-size: 18px;
        margin-top: 15px;
    }

    h3 {
        font-size: 16px;
        margin-top: 10px;
    }

    p {
        font-size: 14px;
        margin-bottom: 10px;
    }

    ul {
        margin-left: 15px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .privacy-policy {
        padding: 15px;
    }

    h1 {
        font-size: 26px;
    }

    h2 {
        font-size: 22px;
        margin-top: 20px;
    }

    h3 {
        font-size: 20px;
        margin-top: 15px;
    }

    p {
        font-size: 18px;
        margin-bottom: 15px;
    }

    ul {
        margin-left: 18px;
    }
}