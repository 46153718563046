/* Reset some default browser styles */
body,
h1,
h2,
p {
    margin: 0;
    padding: 0;

}

.img {
    max-width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Reset some default browser styles */
}

body,
h1,
h2,
p {
    margin: 0;
    padding: 0;
    margin: 0 auto;
}

.img {
    max-width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

/* Basic styling */
body {
    font-family: Arial, sans-serif;
    color: #333;
}

.shipping-policy-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    /* Set your desired background color */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* Add a subtle box shadow */
    border-radius: 8px;
    /* Add some border radius for a card-like look */
}

h1 {
    font-size: 24px;
    margin-bottom: 10px;
}

h2 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
}

p {
    margin-bottom: 15px;
    line-height: 1.5;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
    .shipping-policy-container {
        padding: 10px;
    }

    h1 {
        font-size: 20px;
    }

    h2 {
        font-size: 18px;
    }

    p {
        font-size: 16px;
    }
}



/* Basic styling */
body {
    font-family: Arial, sans-serif;
    color: #333;

}

.shipping-policy-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

h1 {
    font-size: 24px;
    margin-bottom: 10px;
}

h2 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
}

p {
    margin-bottom: 15px;
    line-height: 1.5;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
    .shipping-policy-container {
        padding: 10px;
    }

    h1 {
        font-size: 20px;
    }

    h2 {
        font-size: 18px;
    }

    p {
        font-size: 16px;
    }
}