.pricing-container {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}



.content {
    vertical-align: baseline;
    display: inline-block;
    border-top: 2px black solid;
    border-bottom: 2px black solid;
    margin-left: 10px;
    /* Adjust as needed */
}



.img {
    max-width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 30px;
}

h1 {
    font-size: 24px;
    margin-top: 0;
}

h2 {
    font-size: 20px;
    margin-top: 20px;
}

p {
    font-size: 16px;
    margin-bottom: 10px;
}

@media (max-width: 767px) {
    .pricing-container {
        padding: 10px;
    }

    h1 {
        font-size: 22px;
    }

    h2 {
        font-size: 18px;
        margin-top: 15px;
    }

    p {
        font-size: 14px;
        margin-bottom: 5px;
    }
}