/* App.css */

.App {
    text-align: center;
    padding: 20px;
}

/* .topic {
    text-align: left;
    
  } */

.head {
    text-align: left;
    color: #333;
    margin-bottom: 60px;
    font-size: 32px;
}

.content {
    color: #666;
    line-height: 2.2;
    padding: 30px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.footer {
    color: #555;
    margin-top: 30px;
}

.img {
    max-width: 100%;
    height: 200px;
}

@media screen and (max-width: 768px) {
    .App {
        padding: 10px;
    }

    .content {
        padding: 20px;
        /* Adjust padding for smaller screens */
    }
}

@media screen and (max-width: 480px) {
    .head {
        font-size: 24px;
    }

    .content {
        font-size: 16px;
    }

    .footer {
        font-size: 18px;
    }
}