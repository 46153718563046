/* RefundCancellationPolicy.css */

/* Styles for the policy container */
.policy-container {

    margin: 0 auto;
    padding: 20px;
}

/* Styles for images */
.img {
    max-width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

/* Styles for policy sections */
.policy-section {
    border-top: 1px solid #ccc;
    padding-top: 20px;
    margin-top: 20px;
}

/* Styles for policy subsections */
.policy-subsection {
    margin-bottom: 20px;
}

/* Responsive Styles */
@media screen and (max-width: 1200px) {
    .policy-container {
        max-width: 90%;
        padding: 15px;
    }
}

@media screen and (max-width: 992px) {
    .policy-container {
        padding: 10px;
    }

    .policy-subsection {
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 576px) {
    .policy-subsection {
        margin-bottom: 10px;
    }
}

/* Styles for individual <p> tags */
.policy-container p {
    font-size: 16px;
    line-height: 1.5;
    /* Add any other styles you want for <p> tags */
}

/* Additional responsive styles for <p> tags */
@media screen and (max-width: 576px) {
    .policy-container p {
        font-size: 14px;
    }
}