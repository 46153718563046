/* Teamsandcondition.css */
.terms-container {

    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;

}

.logo {
    max-width: 100px;
    display: block;
    margin: 0 auto 20px;
}

.img {
    max-width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

h3 {
    font-size: 20px;
    margin-top: 20px;
}

p {
    margin: 10px 0;
    line-height: 1.6;
}

a {
    color: #007bff;
    text-decoration: none;
}

/* Responsive Styles */
@media screen and (max-width: 600px) {
    .terms-container {
        padding: 10px;
    }

    h2 {
        font-size: 20px;
    }

    h3 {
        font-size: 18px;
    }
}